import parkRecordTotal from "./parkRecordTotal";
import parkingArrearageOrder from "./parkingArrearageOrder"
import paymentRecord from "./paymentRecord"
import record from "./record"
import parkingCardManage from "./parkingCardManage"
import staggeredOrder from "./staggeredOrder"
import makeAnAppointment from "./makeAnAppointment"
import essentialInformation from "./essentialInformation"
import refundOrderQuery from "./refundOrderQuery"

export default {
  parkRecordTotal,
  parkingArrearageOrder,
  paymentRecord,
  record,
  parkingCardManage,
  staggeredOrder,
  makeAnAppointment,
  essentialInformation,
  refundOrderQuery
}
