<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div
        class="searchWrapper bgFFF"
        @keydown.enter.prevent="searParkRecordList"
      >
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="col_box">
            <div class="col_left">
              <!-- <el-form-item label="城市">
            <el-select
              v-model.trim="formInline.areaId"
              @change="areaChange"
              filterable
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.region')">
                <!-- <el-select
              v-model.trim="formInline.streetId"
              filterable
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select> -->
                <a-cascader
                  ref="cascader"
                  @change="handleAreaChange"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  :dataSource="1"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <el-select
                  v-model.trim="formInline.parkType"
                  filterable
                  placeholder=""
                  size="10"
                >
                  <el-option label="全部" value="-1"></el-option>
                  <el-option
                    :label="park.desc"
                    :value="park.code"
                    :key="park.code"
                    v-for="park in parkType"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')" prop="mobile" ref="mobile">
                <el-input
                  size="12"
                  :maxlength="11"
                  v-model="formInline.mobile"
                  placeholder="手机号"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber" ref="plateNumber">
            <el-autocomplete
              ref="plateNumber"
              size="11"
              valueKey="plateNumber"
              class="inline-input"
              v-model="formInline.plateNumber"
              :fetch-suggestions="querySearchAsync"
              placeholder="车牌号"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
                <el-select
                  v-model.trim="formInline.entryOrExit"
                  filterable
                  size="8"
                >
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in ExitTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.time_slot')">
            <el-date-picker v-model="date1" type="datetime" placeholder="选择日期">
            </el-date-picker>
            至
            <el-date-picker
              v-model="date2"
              type="datetime"
              placeholder="选择日期"
              default-time="23:59:59"
            >
            </el-date-picker>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.time_slot')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <!--<el-form-item label="是否查询临时停车" >
          	<el-select v-model.trim="formInline.parkType" filterable placeholder="" size="10">
	            <el-option label="全部" value="-1"></el-option>
	            <el-option :label="park.desc" :value="park.code" :key="park.code" v-for="park in parkType"></el-option>
            </el-select>
        </el-form-item>-->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()"
                >清空</el-button
              >
            </div>
          </div>

          <!-- <el-button type="primary" icon="el-icon-download" @click="exportFile()" v-if='$route.meta.authority.button.export'>{{ $t('button.export') }}</el-button>  -->
          <!-- <el-button type="primary" icon="el-icon-download" @click="exportFile()">{{ $t('button.export') }}</el-button>  -->
          <!-- <p style="color: #909399">说明：导出数据最多一次导出10,000条，如果筛选条件范围较大，则只能导出前10,000条。</p> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹窗 -->
      <pic-detail
        :infoSize="4"
        :infoDetail="parkDetail"
        :rowData="rowData"
        :detailFlag="dialogVisible"
        :tableOrder="tableOrder"
        :parkRecordIdTable="parkRecordIdTable"
        @close="dialogVisible = false"
        :total="total1"
        :operationTable="operationTable"
        :operationData="operationData"
        :tableOrderdata="tableOrderdata"
        :parkRecordIdData="parkRecordIdData"
        @getOrder="clildrenHandleCurrentChange"
        :entryPic="entryPic"
        :exitPic="exitPic"
        :isCorrect="false"
        :isPaymentRefund="false"
      ></pic-detail>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import picDetail from "../../../components/picDetail/picDetail";
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import InfoList from "../../../components/infoList/infoList";
export default {
  name: "parkRecordTotal",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      defalutDate: ["", ""], // ['', '']
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      roleList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        // {
        //   prop: "cityName",
        //   label: this.$t("list.city"),
        //   width: ""
        // },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: ""
        },
        {
          prop: "parkTypeName",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          formatter: (row) => {
            return this.parkType.find((v) => v.code === row.parkType).desc;
          },
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "100",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPay",
          label: this.$t("list.Actual_received_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableOrderrefund: [
        {
          label: "支付来源",
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          value: "payTime",
        },
        {
          label: "支付方式",
          value: "payTypeDesc",
        },
        {
          label: "已支付金额",
          value: "money",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付流水号",
          value: "paymentId",
        },
      ],
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Actual_received_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_Type"), value: "paymentTypeDesc", width: "" },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      //          if (row.money) {
      //            return Number((row.money) / 100).toFixed(2)
      //          } else {
      //            return '0.00'
      //          }
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      operationTable: [
        {
          label: this.$t("list.Operation_type"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.Operation_source"),
          value: "payTime",
        },
        {
          label: this.$t("list.Operator_or_Equipment"),
          value: "payTypeDesc",
        },
        {
          label: this.$t("list.Operation_time"),
          value: "paymentId",
        },
      ],
      operationData: [],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      page: 1,
      size: 15,
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
      },
    };
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    resetForm() {
      this.$refs.cascader.clear();
      this.$refs["searchWrapper"].clearValidate();
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.operationId = "";
      this.formInline.parkType = "-1";
      // this.formInline.plateNumber = '';
      this.formInline.entryOrExit = 1;
      this.formInline.mobile = "";
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.timeRangePicker.resetTime();
      this.$refs.parkInput.setValue();
      this.formInline.plateNumber = this.$parent.detailInfo.plateNumber || "";
      this.formInline.carId = this.$route.query.carId;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },

    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(data) {
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
      //    this.getRefund();
      // 操作记录
      this.getOperation();
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            dataSource: 1,
            areaIds:
              this.formInline.streetId != ""
                ? this.formInline.streetId
                : this.formInline.areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = res.value;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        // let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        // let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        // this.formInline.startTime = startTime || this.date1;
        // this.formInline.endTime = endTime || this.date2;
        var time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (this.formInline.plateNumber == "" && time > 31) {
          this.$alert("请缩小搜索范围，您可输入车牌号或搜索时间范围不超过31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId:
            this.formInline.streetId != ""
              ? this.formInline.streetId
              : this.formInline.areaId,
          parkId: this.formInline.parkId,
          operationId: this.formInline.operationId,
          parkType: this.formInline.parkType,
          entryOrExit: this.formInline.entryOrExit,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          plateNumber: this.$route.query.plateNumber || "",
          mobile: this.formInline.mobile,
          carId: this.$route.query.carId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      // let flag = this.showLog();
      // this.$refs["searchWrapper"].validate(v => {
      //   if (v) {
      //     if (flag) {
      //       this.loading = true;
      //       this.flag = false;
      //       this.$refs.parkInput.setShowVal(this.formInline.parkName);
      //       this.searParkRecordListFun();
      //     }
      //   }
      // });
      this.loading = true;
      this.flag = false;
      this.$refs.parkInput.setShowVal(this.formInline.parkName);
      this.searParkRecordListFun();
    },
    // 订单记录
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    //  // 退款订单
    //  getRefund() {
    //    let page = this.pageNum || 1
    //    let opt = {
    //      url: '/acb/2.0/parkRecord/payOrder/' + this.rowData.parkRecordId,
    //      method: 'get',
    //      data: {
    //        page: page,
    //        pageSize: this.pageSize
    //      },
    //      success: (res) => {
    //        if (res.state == 0) {
    //          this.tableOrderdataRefund = res.value.list;
    //          this.total1 = res.value.total * 1 || 0;
    //        } else {
    //          this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //            confirmButtonText: this.$t('pop_up.Determine')
    //          });
    //        }
    //      }
    //    };
    //    this.$request(opt);
    //  },
    // 操作记录
    getOperation() {
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: 1,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 导出
    exportFile() {
      let opt = {};
      opt = {
        //          page: this.pageNum,
        //	        pageSize: this.pageSize,
        areaId:
          this.formInline.streetId != ""
            ? this.formInline.streetId
            : this.formInline.areaId,
        parkId: this.formInline.parkId,
        operationId: this.formInline.operationId,
        parkType: this.formInline.parkType,
        entryOrExit: this.formInline.entryOrExit,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        plateNumber: this.formInline.plateNumber,
        mobile: this.formInline.mobile,
        carId: this.formInline.carId,
      };
      exportExcelNew("/acb/2.0/parkRecord/list/export", opt);
    },
  },
  components: {
    // InfoList,
    picDetail,
    myComponent,
    timeRangePick,
  },
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData()
    this.getParkSpinner();
    // if (this.$route.query.memberId) {
    //   //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
    //   this.formInline.startTime = this.$route.query.bindTime
    //     ? this.$route.query.bindTime
    //     : this.$route.query.createdTime
    //     ? this.$route.query.createdTime
    //     : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
    //   this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
    //   this.date1 = this.formInline.startTime;
    //   this.date2 = this.formInline.endTime;
    //   if (this.$route.query.plateNumber) {
    //     this.plateNumber =
    //       this.$route.query.plateNumber.split(",").length > 1 ? "" : this.$route.query.plateNumber;
    //   }
    //   this.formInline.plateNumber = this.plateNumber;
    //   this.formInline.mobile = this.$route.query.mobile;
    //   this.flag = true;
    //   this.searParkRecordListFun();
    // }
    // this.defalutDate = [this.dataTimeRest() + ' 00:00:00', this.dataTimeRest() + ' 23:59:59']
    this.formInline.plateNumber = this.$parent.detailInfo.plateNumber || "";
    this.formInline.carId = this.$route.query.carId;
  },
  mounted() {
    this.searParkRecordListFun();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
