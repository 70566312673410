<template>
  <div class="essential-information-box">
    <div class="information-box">
      <el-row>
        <el-col :span="8">
          <div>
            <el-image
              style="width: 100%"
              :src="imgUrl"
              :preview-src-list="imgSrcList"
            >
              <div slot="error" class="image-slot">
                <div class="default-error-img">
                  <img src="../../../assets/img/car-pic.png" alt="">
                </div>
              </div>
            </el-image>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="information-box-right">
            <h2 class="title">
              <div class="title_icon"></div>
              车辆归属信息
            </h2>
            <el-form
              :inline="true"
              :model="ascriptionForm"
              class="ascription-form"
            >
              <el-form-item
                v-for="(item, i) in ascriptionFormLabel"
                :key="i"
                :label="item.label"
              >
                <span v-if="item.key == 'authStatus'">{{
                  ascriptionForm[item.key] | authStatusFilter
                }}</span>
                <span v-else-if="item.key == 'plateColor'">{{
                  ascriptionForm[item.key]
                }}</span>
                <span v-else>{{ ascriptionForm[item.key] }}</span>
              </el-form-item>
            </el-form>
            <h2 class="title btn-parent">
              <div class="title_icon"></div>
              车辆外观信息
              <div class="btn-box">
                <el-button
                  v-if="!editStatus"
                  type="primary"
                  size="small"
                  @click="handleEdit"
                  >{{ $t('button.edit') }}</el-button
                >
                <el-button
                  v-if="editStatus"
                  type="primary"
                  size="small"
                  @click="handleSave"
                  >{{ $t('button.preservation') }}</el-button
                >
                <el-button v-if="editStatus" size="small" @click="handleCancel"
                  >取消</el-button
                >
              </div>
            </h2>
            <el-form
              :inline="true"
              :model="ascriptionForm"
              :disabled="!editStatus"
              class="ascription-form"
            >
              <el-form-item
                v-for="(item, i) in appearanceFormLabel"
                :key="i"
                :label="item.label"
              >
                <el-input
                  v-if="item.type == 'input'"
                  v-model="ascriptionForm[item.key]"
                  :placeholder="`请输入${item.label}`"
                ></el-input>
                <el-select
                  v-if="item.type == 'select'"
                  v-model="ascriptionForm[item.key]"
                  :placeholder="`请选择${item.label}`"
                >
                  <el-option
                    v-for="(item, i) in item.selectType == 'vehicle'
                      ? carTypeList
                      : plateTypeList"
                    :key="i"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="record-box">
      <div class="vehicle-binding-box">
        <h2 class="title">
          <div class="title_icon"></div>
          车辆绑定记录
        </h2>
        <el-table  :data="vehicleBindingData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in vehicleBindingCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <div class="vehicle-update-box">
        <h2 class="title">
          <div class="title_icon"></div>
          车辆信息修改记录
        </h2>
        <el-table  :data="vehicleUpdateData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in vehicleUpdateCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "essentialInformation",
  data() {
    return {
      imgUrl: "",
      imgSrcList: [],
      ascriptionFormLabel: [
        {
          label: "车牌颜色:", // 待联调
          key: "plateColor",
        },
        {
          label: "车牌号:",
          key: "plateNumber",
        },
        {
          label: "绑定会员账户:",
          key: "mobile",
        },
        {
          label: "认证状态:",
          key: "authStatus",
        },
        {
          label: "车牌归属地:", // 待联调
          key: "placeOwnership",
        },
        {
          label: "创建时间:", // 待联调
          key: "createdTime",
        },
      ],
      ascriptionForm: {
        plateColor: "", // 车牌颜色
        plateNumber: "", // 车牌号
        mobile: "", // 绑定会员账户
        authStatusDesc: "", // 认证状态
        authStatus: '',
        placeOwnership: "", // 车牌归属地
        createdTime: "", // 创建时间
        brand: "", // 车辆品牌
        carModel: "", // 车辆型号
        carColor: "", // 车身颜色
        carType: "", // 车辆类型
        plateType: "", // 号牌类型
      },
      editStatus: false,
      appearanceFormLabel: [
        {
          type: "input",
          label: "车辆品牌",
          key: "brand",
        },
        {
          type: "input",
          label: "车辆型号", //
          key: "carModel",
        },
        {
          type: "input",
          label: "车身颜色",
          key: "carColor",
        },
        {
          type: "select",
          label: this.$t("list.Vehicle_type"),
          key: "carType",
          selectType: "vehicle",
        },
        {
          type: "select",
          label: "号牌类型",
          key: "plateType",
        },
      ],
      vehicleInit: {
        // 外观信息 取消按钮初始化数据
        plateColor: "", // 车牌颜色
        plateNumber: "", // 车牌号
        mobile: "", // 车牌号
        authStatusDesc: "", // 认证状态
        authStatus: '',
        placeOwnership: "", // 车牌归属地
        createdTime: "", // 创建时间
        brand: "", // 车辆品牌
        carModel: "", // 车辆型号
        carColor: "", // 车身颜色
        carType: "", // 车辆类型
        plateType: "", // 号牌类型
      },
      carTypeList: [],
      plateTypeList: [
        {
          code: "大型汽车",
          desc: "大型汽车",
        },
        {
          code: "小型汽车",
          desc: "小型汽车",
        },
        {
          code: "使馆汽车",
          desc: "使馆汽车",
        },
        {
          code: "领馆汽车",
          desc: "领馆汽车",
        },
        {
          code: "境外汽车",
          desc: "境外汽车",
        },
        {
          code: "外籍汽车",
          desc: "外籍汽车",
        },
        {
          code: "教练汽车",
          desc: "教练汽车",
        },
        {
          code: "临时行驶车",
          desc: "临时行驶车",
        },
        {
          code: "警用汽车",
          desc: "警用汽车",
        },
        {
          code: "大型新能源汽车",
          desc: "大型新能源汽车",
        },
        {
          code: "小型新能源汽车",
          desc: "小型新能源汽车",
        },
        {
          code: "残疾人专用车号牌",
          desc: "残疾人专用车号牌",
        },
      ],
      vehicleBindingData: [],
      vehicleBindingCols: [
        {
          prop: "mobile",
          label: "绑定用户手机",
          width: "",
        },
        {
          prop: "bindTime",
          label: this.$t("list.Binding_time"),
          width: "",
        },
        {
          prop: "unbindTime",
          label: "解绑时间",
          width: "",
        },
        {
          prop: "authStatus",
          label: "认证状态",
          width: "",
          formatter: (row, column) => {
            if (row.authStatus == 1) {
              return "已认证";
            } else {
              return "未认证";
            }
          },
        },
      ],
      vehicleUpdateData: [],
      vehicleUpdateCols: [
        {
          prop: "modifyType",
          label: "修改类型",
          width: "",
        },
        {
          prop: "modifyContext",
          label: "修改内容",
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "updatedTime",
          label: "修改时间",
          width: "",
        },
      ],
      carId: "",
      memberId: "",
      carColorList: [],
    };
  },
  created() {
    this.carId = this.$parent.$route.query.carId;
    this.memberId = this.$parent.$route.query.memberId;
    this.ascriptionForm.plateColor = this.$parent.$route.query.plateColor;
    this.ascriptionForm.plateNumber = this.$parent.$route.query.plateNumber;
    this.ascriptionForm.createdTime = this.$parent.$route.query.strCreatedTime;
    this.ascriptionForm.mobile = this.$parent.$route.query.mobile;
    this.ascriptionForm.authStatusDesc =
      this.$parent.$route.query.authStatusDesc;

    this.allParkType(); // 获取车辆类型
    this.handleGetCarAppearance(); // 获取车辆外观信息
    this.handleGetCarBindRecord(); // 获取车辆绑定记录
    this.handleGetCarUpdateRecord(); // 获取车辆修改记录
    this.getColor();
    this.getDetailInfo();
  },
  methods: {
    // 车辆类型
    allParkType() {
      let url = "/acb/2.0/feeScene/carTypeList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.carTypeList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.carColorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    // 获取详细信息
    getDetailInfo() {
      const opt = {
        url: "/acb/2.0/memberCarAuth/getCarAuthDetail",
        method: "get",
        data: {
          carId: this.carId,
          memberId: this.memberId,
        },
        success: (res) => {
          this.ascriptionFormLabel.forEach((item) => {
            if (!['placeOwnership', 'createdTime'].includes(item.key)) {
              this.ascriptionForm[item.key] = res.value[item.key];
            };
            this.vehicleInit[item.key] = res.value[item.key];
          });
        },
      };
      this.$request(opt);
    },
    handleGetCarAppearance() {
      const carId = this.$parent.$route.query.carId;
      const opt = {
        url: "/acb/2.0/getCarInfoByCarId",
        method: "get",
        data: {
          carId: carId,
        },
        success: (res) => {
          const carUrl = res.value.carUrl || "";
          if (carUrl) {
            this.imgUrl = carUrl;
            this.imgSrcList = [carUrl];
          }
          this.appearanceFormLabel.forEach((item) => {
            this.ascriptionForm[item.key] = res.value[item.key];
            this.vehicleInit[item.key] = res.value[item.key];
          });
          this.ascriptionForm.placeOwnership = res.value.territory;
          this.ascriptionForm.createdTime = res.value.createdTime;
          this.vehicleInit.placeOwnership = res.value.territory;
        },
      };
      this.$request(opt);
    },
    handleGetCarBindRecord() {
      const opt = {
        method: "get",
        url: "/acb/2.0/getCarBindRecord",
        data: {
          carId: this.carId,
        },
        success: (res) => {
          this.vehicleBindingData = res.value.list || [];
        },
        fail: () => {},
      };
      this.$request(opt);
    },
    handleGetCarUpdateRecord() {
      const opt = {
        method: "get",
        url: "/acb/2.0/getModifyHistory",
        data: {
          carId: this.carId,
        },
        success: (res) => {
          this.vehicleUpdateData = res.value || [];
        },
        fail: () => {},
      };
      this.$request(opt);
    },
    // 编辑按钮改变状态
    handleEdit() {
      this.editStatus = !this.editStatus;
    },
    // 取消按钮初始化
    handleCancel() {
      this.handleEdit();
      this.$set(this, "ascriptionForm", this.vehicleInit);
    },
    // 车辆外观信息 保存
    handleSave() {
      let params = {};
      this.appearanceFormLabel.forEach((ele) => {
        params[ele.key] = this.ascriptionForm[ele.key];
      });
      params.carId = this.carId;

      const opt = {
        method: "post",
        url: "/acb/2.0/modifyCarInfo",
        data: params,
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        success: (res) => {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          this.editStatus = false;
          this.handleGetCarAppearance(); // 获取车辆外观信息
          this.handleGetCarUpdateRecord();
        },
        fail: () => {
          this.$set(this, "ascriptionForm", this.vehicleInit);
        },
      };
      this.$request(opt);
    },
  },
  filters: {
    authStatusFilter(val) {
      const result = ["待审核", "已认证", "审核不通过"];
      return result[val];
    },
    plateColorFilter(val, carColorList) {
      let colorText = "";
      for (let i in carColorList) {
        if (carColorList[i].code == val) {
          colorText = carColorList[i].desc;
        }
      }
      return colorText;
    },
  },
};
</script>
<style lang="stylus" scoped>
.essential-information-box {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 22px;
  box-sizing: border-box;

  .information-box-right {
    width: 100%;
    padding-left: 6%;
    box-sizing: border-box;
  }

  .ascription-form, .ascription-form {
    .el-form-item {
      width: 46%;

      /deep/ .el-form-item__label, .el-form-item__content {
        font-size: 16px;
      }
    }

    .el-form-item__content {
      width: 210px;
    }
  }

  .btn-parent {
    position: relative;
  }

  .btn-box {
    position: absolute;
    left: 160px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.vehicle-binding-box {
  margin-bottom: 20px;
}
.default-error-img {
  line-height: 224px;
    text-align: center;
}
</style>
