<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="预约订单" name="first">
        <bookingOrder />
      </el-tab-pane>
      <el-tab-pane label="预约订单退款" name="second">
        <reservation-refund />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import bookingOrder from "./bookingOrder"
import reservationRefund from "./reservationRefund"
export default {
  data() {
    return {
      activeName: 'first'
    };
  },
  components: {
    bookingOrder,
    reservationRefund
  }
};
</script>
<style lang="stylus" scoped></style>
