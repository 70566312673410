<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper bgFFF" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="1"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.plate_number')"
                        prop="plateNumber">
            <el-autocomplete valueKey='plateNumber'
                             class="inline-input"
                             v-model.trim="plateNumber"
                             :fetch-suggestions="querySearchAsync"
                             placeholder="请输入内容"
                             :trigger-on-focus="false"
                             @select="handleSelect"></el-autocomplete>
          </el-form-item> -->
              <!-- <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select v-model.trim="formInline.payType"
                       filterable
                       placeholder=""
                       size="12">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="pay.payTypeName"
                         :value="pay.payType"
                         :key="key"
                         v-for="(pay, key) in payTypeList"></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_channels')">
                <el-select v-model="formInline.channelType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="area.channelTypeDesc"
                    :value="area.channelType"
                    :key="area.channelType"
                    v-for="area in payList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_devices')">
                <el-select
                  v-model.trim="formInline.devType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in exqList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Operator')">
            <el-input v-model="formInline.operatorName"
                      placeholder="请输入内容"></el-input>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_Time')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
              <el-button icon="el-icon-delete" @click="resetForm()"
                >清空</el-button
              >
              <!-- v-if='$route.meta.authority.button.export' -->
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile style="display: inline-block" :exportData="exportData"
                >导出</exportFile
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import myComponent from "@/components/autocomplete/myautoComponent";
import { dateFormat, setIndex } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "payRecordQuery",
  data() {
    return {
      defalutDate: ["", ""],
      payList: [],
      exqList: [],
      options: [
        {
          value: 1,
          label: this.$t("list.entry_time"),
        },
        {
          value: 0,
          label: this.$t("list.Appearance_time"),
        },
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      plateNumber: "",
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      payTypeList: [],
      tableCols: [{
        prop: 'payOrderId',
        label: '订单ID',
        width: ''
      }, {
        prop: 'parkName',
        label: '道路/车场',
        width: ''
      }, {
        prop: 'berthNumber',
        label: '泊位号',
        width: ''
      }, {
        prop: 'plateNumber',
        label: '车牌号',
        width: ''
      }, {
        prop: 'entryTime',
        label: '入场时间',
        width: ''
      }, {
        prop: 'exitTime',
        label: '计费时间',
        width: ''
      }, {
        prop: 'strParkTime',
        label: '停车时长',
        width: ''
      },
      {
        prop: 'shouldPay',
        label: '应收金额',
        width: '',
        formatter: (row, column) => {
          if (row.shouldPay) {
            return Number((row.shouldPay) / 100).toFixed(2)
          } else {
            return '0.00'
          }
        }
      },
      {
        prop: 'agioPay',
        label: '优惠金额',
        width: '',
        formatter: (row, column) => {
          if (row.agioPay) {
            return Number((row.agioPay) / 100).toFixed(2)
          } else {
            return '0.00'
          }
        }
      },
      {
        prop: 'money',
        label: '实收金额',
        width: '',
        formatter: (row, column) => {
          if (row.money) {
            return Number((row.money) / 100).toFixed(2)
          } else {
            return '0.00'
          }
        }
      },
      //  {
      //   prop: 'payTypeDesc',
      //   label: '支付方式',
      //   width: ''
      // },
      {
        prop: 'channelTypeDesc',
        label: '支付渠道'
      },
       {
        prop: 'devTypeDesc',
        label: '支付设备'
      }, {
        prop: 'operatorName',
        label: '操作人',
        width: ''
      }, {
        prop: 'payParkName',
        label: '收费道路/车场',
        width: ''
      }, {
        prop: 'payTime',
        label: '支付时间',
        width: ''
      }, {
        prop: 'tradeNo',
        label: '第三方流水ID',
        width: ''
      }
      ],
      tableData: [],
      formInline: {
        parkId: "",
        parkName: "",
        payType: "",
        carId: "",
        startTime: "",
        endTime: "",
        channelType: "",
        payList: "",
        // timeConditionType: 1
        devType: "",
        dataSource: 1,
        name: `停车支付订单`,
        operatorName: "",
        plateNumber: "",
      },
      exportData: {},
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    resetForm() {
      this.plateNumber = "";
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "1",
        // plateNumber: '',
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        // timeConditionType: 1,
        payType: "",
        channelType: "",
        devType: "",
        dataSource: 1,
        name: `停车支付订单`,
        operatorName: "",
        plateNumber: this.$parent.detailInfo.plateNumber || "",
        carId: this.$route.query.carId,
      };
      this.$refs.timeRangePicker.resetTime();
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.exportData = {
        url: "/acb/2.0/payOrder/payOrderList/export",
        methods: "post",
        data: this.formInline,
      };
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payment/payTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searchData() {
      // let flag = this.showLog();
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/payOrderList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          payType: this.formInline.payType,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          // timeConditionType: this.formInline.timeConditionType
          channelType: this.formInline.channelType,
          devType: this.formInline.devType,
          dataSource: this.formInline.dataSource,
          operatorName: this.formInline.operatorName, // 操作人
          plateNumber: this.formInline.plateNumber, // 车牌号
          berthCode: "",
        },
        success: (res) => {
          this.tableData = [];
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      // if (flag) {
      this.loading = true;
      this.$request(opt);
      // }
    },
    getExq(data) {
      this.$axios
        .get("/acb/2.0/payOrder/devTypeList")
        .then((res) => {
          // console.log("测试", res)
          this.exqList = res.value;
        })
        .catch(() => {
          this.exqList = [];
        });
    },
    getPayType(data) {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          // console.log("测试", res)
          this.payList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
  },
  components: {
    myComponent,
    exportFile,
    timeRangePick,
  },
  activated() {
    this.searchData();
  },
  created() {
    // this.defalutDate = [this.dataTimeRest() + ' 00:00:00', this.dataTimeRest() + ' 23:59:59']
    this.formInline.plateNumber = this.$parent.detailInfo.plateNumber || "";
    this.formInline.carId = this.$route.query.carId;
  },
  mounted() {
    this.getExq();
    this.getPayType();
    this.getPay();
    // let date1 = new Date();
    // date1.setHours(0);
    // date1.setMinutes(0);
    // date1.setSeconds(0);
    // let startTime = date1;
    // let date = new Date();
    // date.setHours(23);
    // date.setMinutes(59);
    // date.setSeconds(59);
    // let endTime = date;
    // this.formInline = {
    //   parkId: '',
    //   parkName: '',
    //   payType: '',
    //   carId: '',
    //   startTime: dateFormat(startTime),
    //   endTime: dateFormat(endTime),
    //   // timeConditionType: 1,
    //   name: `停车支付订单`,
    //   channelType: '',
    //   devType: '',
    //   dataSource: 1,
    // }
    // 导出数据
    // console.log(this.formInline);
    this.exportData = {
      url: "/acb/2.0/payOrder/payOrderList/export",
      methods: "post",
      data: this.formInline,
    };
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding 22px 22px 0
    // margin-bottom 20px
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
