<template>
  <div>
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="$router.go(-1)" type="primary">返回</el-button>
      </div>
    </div>
    <!-- tab栏 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      ></el-tab-pane>
    </el-tabs>
    <!-- <div v-if="activeName == 'basic'">
      <div class="content bgFFF">
        <h2 class="title">
          <div class="title_icon"></div>
          车辆属性
        </h2>
        <div style="margin-bottom: 50px" class="attribute">
          <p>
            车牌信息：<span>{{ detailInfo.plateNumber }}</span>
          </p>
          <p>
            手机号码：<span>{{ detailInfo.mobile }}</span>
          </p>
          <p>
            车型分类：<span>{{ detailInfo.carType | carTypeFilter }}</span>
          </p>
        </div>
        <hr v-if="isAuth === 'true'" />
        <h2 class="title" v-if="isAuth === 'true'">
          <div class="title_icon"></div>
          认证信息
        </h2>
        <div
          class="Wrapper"
          style="float: left; width: 100%"
          v-if="isAuth === 'true'"
        >
          <el-form
            label-position="left"
            label-width="160px"
            :model="formInline"
            style="width: 100%; float: left"
            :ref="'form'"
          >
            <el-form-item
              label="车辆认证状态："
              label-width="120px"
              style="float: left; width: 33%"
              prop="slaveRelation"
            >
              <span>{{ detailInfo.authStatus | authStatusFilter }}</span>
            </el-form-item>
            <el-form-item
              :label="$t('searchModule.Car_owner_name')"
              label-width="120px"
              style="width: left; width: 60%"
            >
              <span>{{ detailInfo.carOwnerName }}</span>
            </el-form-item>
            <el-form-item
              label="车辆编码："
              label-width="100px"
              style="float: left; width: 33%"
              prop="label"
            >
              <span>{{ detailInfo.frameNo }}</span>
            </el-form-item>
            <el-form-item
              label="发动机编码："
              label-width="100px"
              style="float: left; width: 50%"
              v-if="this.labelnumber == 0"
              prop="parkCode"
            >
              <span>{{ detailInfo.engineNo }}</span>
            </el-form-item>
            <el-form-item
              label="行驶本照片："
              label-width="100px"
              style="float: left; width: 100%"
              prop="slaveRelation"
            >
            </el-form-item>
            <div style="margin: 0 auto; width: 60%">
              <el-row :gutter="12" style="margin-top: 20px">
                <el-col :span="12">

                  <div class="img">
                    <img
                      :src="detailInfo.frontAuthImage"
                      @click="showBigPic(detailInfo.frontAuthImage)"
                      alt=""
                      style="width: 100%; height: 180px"
                    />
                  </div>

                </el-col>
                <el-col :span="12">

                  <div class="img">
                    <img
                      :src="detailInfo.backAuthImage"
                      @click="showBigPic(detailInfo.backAuthImage)"
                      alt=""
                      style="width: 100%; height: 180px"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
      <transition name="fade">
        <div
          class="mask"
          v-show="bigPic"
          :style="{
            width: '500px',
            height: 'auto',
            margin: '0 auto',
            'z-index': '10000',
            'background-image': picUrl,
          }"
          @click="bigPic = false"
        ></div>
      </transition>
    </div> -->
    <!-- 基本信息new -->
    <essential-information v-if="activeName == 'basic'" />
    <!-- 停车记录 -->
    <park-record-total v-if="activeName == 'parking'" />
    <!-- 欠费记录 -->
    <parking-arrearage-order v-if="activeName == 'arrears'" />
    <!-- 支付记录 -->
    <payment-record v-if="activeName == 'payment'" />
    <!-- 退费记录 -->
    <!-- <record v-if="activeName == 'refund'" /> -->
    <refund-Order-Query v-if="activeName == 'refund'" />
    <!-- 停车卡购买记录 -->
    <parking-card-manage v-if="activeName == 'parkingCard'" />
    <!-- 错峰卡购买记录 -->
    <staggered-order v-if="activeName == 'peakStaggeringCard'" />
    <!-- 预约记录 -->
    <make-an-appointment v-if="activeName == 'makeAnAppointment'" />
  </div>
</template>
<script>
import detailComponents from "./components";
export default {
  filters: {
    carTypeFilter(val) {
      const result = ["小型车", "中型车", "大型车"];
      return result[val];
    },
    authStatusFilter(val) {
      const result = ["待审核", "已认证", "审核不通过"];
      return result[val];
    },
  },
  watch: {
    $route(to, from) {
      // this.getDetailInfo();
    },
  },
  provide() {
    return {
      app: this,
    };
  },
  data() {
    return {
      picUrl: "",
      bigPic: false,
      labelnumber: "0",
      openTime: "",
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      parkEquipmentType: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      formInline: {
        portrait: "",
        remark: "",
        parkName: "",
        areaId: "",
        parkCode: "账号注册时间<30天;已支付订单=0;",
        slaveRelation: 0,
        innerPayable: "",
        parentId: "",
        parkAddress: "",
        imageUrl: "",
        amount: "",
        parkType: "",
        businessOwner: "",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        contract: "",
        openStartTime: "",
        openStopTime: "",
        parkEquipmentType: "",
      },
      detailInfo: {},
      isAuth: this.$route.query.isAuth,
      activeName: "basic",
      tabList: [
        {
          label: "基本信息",
          name: "basic",
        },
        {
          label: "停车记录",
          name: "parking",
        },
        {
          label: "欠费记录",
          name: "arrears",
        },
        {
          label: "支付记录",
          name: "payment",
        },
        {
          label: "退费记录",
          name: "refund",
        },
        {
          label: "停车卡购买记录",
          name: "parkingCard",
        },
        {
          label: "错峰卡购买记录",
          name: "peakStaggeringCard",
        },
        {
          label: "预约记录",
          name: "makeAnAppointment",
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {},
    // 获取详细信息
    getDetailInfo() {
      const carId = this.$route.query.carId;
      const memberId = this.$route.query.memberId;
      const opt = {
        url: "/acb/2.0/memberCarAuth/getCarAuthDetail",
        method: "get",
        data: {
          carId: carId,
          memberId: memberId,
        },
        success: (res) => {
          this.detailInfo = res.value;
        },
      };
      this.$request(opt);
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline = data;
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.lnglat =
            data.longitude / 1000000 + "," + data.latitude / 1000000;
          this.openTime = [
            new Date(this.formInline.openStartTime),
            new Date(this.formInline.openStopTime),
          ];
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.imageUrl = res.value;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  created() {
    this.formInline.portrait =
      "https://img04.sogoucdn.com/v2/thumb/resize/w/339/t/2/retype/ext/auto/q/90/?appid=200698&name=349_166_20170824160838-1376751992";
    // this.getParkImg(this.$route.query.parkId);
    // this.getParkDetail(this.$route.query.parkId);
    // this.getDetailInfo();
  },
  components: {
    ...detailComponents,
  },
};
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 50px;
}

.content {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 20px;
}

.uploadPic {
  float: left;
  margin-left: 150px;
  width: 295px;
  height: 178px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
  }
}

.mask {
  background: rgba(0, 0, 0, 0) no-repeat center / contain;
  // background: rgba(49,53,65,0.60) no-repeat center/contain;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
<style>
.dialog_button {
  width: 100%;
  height: 40px;
}
.attribute {
  width: 100%;
  height: 30px;
}
.attribute p {
  width: 25%;
  float: left;
}
</style>
