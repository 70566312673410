<template>
  <!--预约订单详情-->
  <div class="yuYueOrderDetails">
    <!-- 内容 -->
    <div class="content">
      <h2 class="title">
        <span class="title_icon"></span>
        订单信息
      </h2>
      <el-form label-position="right" style="padding-left:100px">
        <el-row>
          <el-col :span="10">
            <el-form-item :label="$t('searchModule.plate_number')">
              <el-select v-model="carId">
                <el-option
                  :label="value.plateNumber"
                  :value="value.carId"
                  v-for="value in cars"
                  :key="value.plateNumber"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="车牌号:">
              <span class="spanA">{{carId}}</span>
            </el-form-item>-->
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="车牌颜色:">
              <span class="spanA">{{carColor}}</span>
            </el-form-item>
          </el-col>-->
          <el-col :span="6">
            <el-form-item label="订单状态:">
              <span class="spanA">{{getStateName(formInline.orderState)}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="手机号:">
          <span class="spanA">{{formInline.mobile}}</span>
        </el-form-item>
        <el-form-item label="停车场名称：">
          <span class="spanA">{{formInline.parkName}}</span>
        </el-form-item>
        <el-form-item label="预约到场时间：">
          <span class="spanA">{{formInline.appointmentStartTime}}</span>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="车位最晚保留到:">
              <span class="spanA">{{formInline.appointmentEndTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="取消时间:" v-if="formInline.orderState > 1">
              <span class="spanA">{{formInline.appointmentCancelTime}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <h2 class="title">
        <span class="title_icon" v-if="!formInline.checkDepositMoney"></span>
        支付信息
      </h2>
      <el-form
        label-position="right"
        style="padding-left:100px"
        v-if="!formInline.checkDepositMoney"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="实付金额:" class="parknum">
              <span class="spanA">{{(formInline.payMoney / 100).toFixed(2)}}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付方式:" class="parknum">
              <span class="spanA">{{formInline.payTypeName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="支付时间:" prop="yajin">
              <span class="spanA">{{formInline.payTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第三方流水号:" prop="payOutTradeNo">
              <span class="spanA">{{formInline.payOutTradeNo}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- <h2 class="title">
        <span class="title_icon"></span>
        退费信息
      </h2>
      <el-form label-position="right" style="padding-left:100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="退费金额:" prop="tiqian">
              <span class="spanA">{{tuifeiJine}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="退费渠道:" prop="quxiao">
              <span class="spanA">{{tuifeiQudao}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="退费时间:" prop="tuihuan">
              <span class="spanA">{{tuifeiTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="第三方流水号:" prop="yajin">
              <span class="spanA">1645056224105454521</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>-->
      <div style="margin:0 auto;width:250px;margin-bottom:20px;">
        <el-button type="primary" @click="submitData" style="width: 88px;">提交</el-button>
        <el-button type="button" @click="goback">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "bookingOrderModify",
  props: ['seeData'],
  data() {
    return {
      parkAppointmentOrderId: "",
      memberId: "",
      carId: "",
      // carColor: "",
      // orderState: "",
      // phone: "",
      // packName: "",
      // appointmentCancelTime: "",
      // zuiwan: "",
      // quxiao: "",
      // shifujine: "",
      // payType: "",
      // payTime: "",
      // // tuifeiJine: "",
      // // tuifeiQudao: "",
      // // tuifeiTime: "",
      // payOutTradeNo: "",
      // refundOutTradeNo: "",
      cars: [],
      formInline: {},
    };
  },
  created() {
    this.appointmentOrderId = this.seeData.appointmentOrderId;
    this.memberId = this.seeData.memberId;
    this.inItData(this.appointmentOrderId);
    this.getPlateNumber();
  },
  methods: {
    goback() {
      // this.$router.go(-1);
      this.$emit('close')
    },
    getStateName(state) {
      return [
        "进行中",
        "已完成",
        "超时自动取消",
        "用户手动取消",
        "用户放弃预约",
        "后台取消",
      ][state];
    },
    inItData(id) {
      this.$axios
        .get("/acb/2.0/parkAppointmentOrder/park/appointment/findById/" + id)
        .then((res) => {
          this.formInline = res.value;
          this.carId = res.value.carId;
          // if (res.state == 0) {
          //   let formatter = [
          //     "进行中",
          //     "已完成",
          //     "超时自动取消",
          //     "用户手动取消",
          //     "用户放弃预约",
          //     "后台取消",
          //   ][res.value.orderState];

          //   this.carId = res.value.plateNumber;
          //   this.carColor = res.value.plateColor;
          //   this.orderState = formatter;
          //   this.phone = res.value.mobile;
          //   this.packName = res.value.parkName;
          //   this.appointmentCancelTime = res.value.appointmentStartTime;
          //   this.zuiwan = res.value.appointmentEndTime;
          //   this.quxiao =
          //     res.value.orderState == 3 ? res.value.actualFinish : "";
          //   if (res.value.payMoney) {
          //     this.shifujine = res.value.payMoney / 100;
          //   }
          //   this.payType = res.value.payTypeName;
          //   this.payTime = res.value.payTime;
          //   this.memberId = res.value.memberId;
          //   this.payOutTradeNo = res.value.payOutTradeNo;
          // if (res.value.refundMoney) {
          //   this.tuifeiJine = res.value.refundMoney / 100;
          // }
          // this.tuifeiQudao = res.value.refundTypeName;
          // this.tuifeiTime = res.value.refundTime;
          // }
        });
    },
    getPlateNumber() {
      this.$axios
        .get("/acb/2.0/memberInfo/listMemberCar", {
          data: {
            memberId: this.memberId,
          },
        })
        .then((res) => {
          this.cars = res.value;
        });
    },
    submitData() {
      this.$axios
        .post("/acb/2.0/parkAppointmentOrder/modify", {
          data: {
            appointmentOrderId: this.appointmentOrderId,
            memberId: this.memberId,
            carId: this.carId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.$router.go(-1);
            this.$emit('close')
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.title {
  font-size: 14px;
}

.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .inputTip {
    margin-left: 20px;
    color: #999;
  }

  .oRelative {
    position: relative;

    span {
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}

.spanA {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
</style>
